import React from 'react';
import logoGfx from '../../gfx/logo.png';
import {Link} from "react-router-dom";

function TopBar({centered = false}) {
    return (
        <div className="TopBar container">
            <div className="row mt-5">
                <div className={`col${centered ? ' text-center' : ''}`}>
                    <Link to="/">
                        <img src={logoGfx} alt="" className="img-fluid"/>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default TopBar;