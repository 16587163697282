import React from 'react';
import DateTime from 'react-datetime';
import 'moment/locale/de';
import InputError from "./InputError";

function DatePickerInput({name, label, value, onChange, autoFocus, helpText, error, ...otherProps}) {
  return (
    <div className="DatePickerInput form-group">
      <label>{label}</label>
      <DateTime {...otherProps} className="mt-n1" locale="de" value={value} onChange={val => onChange ? onChange(val) : null}/>
      {helpText && (
        <span className="help-block small d-block">{helpText}</span>
      )}
      <InputError error={error}/>
    </div>
  )
}

export default DatePickerInput;