import {PERSON_LOAD, PERSON_LOAD_SUCCESS} from "../../config/actions";

export const personLoad = () => {
    return {
        type: PERSON_LOAD
    }
};

export const personLoadSuccess = (data) => {
    return {
        type: PERSON_LOAD_SUCCESS,
        person: data.person,
        availableProjects: data.availableProjects,
        news: data.news
    }
};