import {PERSON_LOAD_SUCCESS} from "../../config/actions";

let defaultState = {
    person: null,
    availableProjects: null,
    loaded: false
};

const personReducer = (state = defaultState, action) => {
    switch (action.type) {
        case PERSON_LOAD_SUCCESS:
            return {
                ...state,
                person: action.person,
                availableProjects: action.availableProjects,
                news: action.news
            }
        default:
            return state;
    }
}

export default personReducer;