import i18n from 'i18next';
import {initReactI18next} from "react-i18next";

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'de',
        fallbackLng: 'de',
        debug: true,
        saveMissing: true,
        keySeparator: false,

        interpolation: {
            escapeValue: false
        },

        react: {
            useSuspense: false,
            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
        },

        backend: {
            loadPath: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_I18N_LOAD_PATH}/{{lng}}/{{ns}}`,
            addPath: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_I18N_ADD_PATH}/{{lng}}/{{ns}}`,
            allowMultiLoading: false,
            crossDomain: true,
            withCredentials: false
        }
    });

export default i18n;