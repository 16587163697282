import React, {useState} from 'react';
import OutsideContainer from "../../components/layout/OutsideContainer";
import TextInput from "../../components/forms/TextInput";
import Button from "../../components/forms/Button";
import {authorizationLoginSuccess} from "../../store/authorization/authorizationAction";
import AuthorizationApi from "../../api/AuthorizationApi";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {ROUTE_FORGOT_PASSWORD} from "../../config/routes";
import {Link} from "react-router-dom";

function Login() {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    let handleSubmit = (e) => {
        e.preventDefault();
        if (username.length === 0 || password.length === 0) {
            setError(true);
            return;
        }
        setLoading(true);
        AuthorizationApi.login(username, password)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    let {access_token, username, roles} = response.data;
                    if (roles.includes('ROLE_PERSON')) {
                        dispatch(authorizationLoginSuccess(access_token, username, roles));
                    } else {
                        setError(true);
                    }
                } else {
                    setError(true);
                }
            })
            .catch(() => {
                setLoading(false);
                setError(true);
            })
    }

    return (
        <OutsideContainer>
            <div className="Login">
                <div className="row">
                    <div className="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="mb-0">
                                    Login
                                </h5>
                            </div>
                            <div className="card-body">
                                Bitte melde dich an.
                                {error && (
                                    <div className="alert alert-warning mt-3">
                                        {t(`errors.login.general`, "Bei der Anmeldung ist ein Fehler aufgetreten. Bitte versuche es erneut.")}
                                    </div>
                                )}
                                <div className="mt-3">
                                    <form onSubmit={handleSubmit}>
                                        <TextInput label="E-Mail-Adresse" autoFocus value={username} onChange={val => setUsername(val)}/>
                                        <TextInput label="Passwort" type="password" value={password} onChange={val => setPassword(val)}/>
                                        <div className="mt-2">
                                            <Button label="Login" loading={loading}/>
                                        </div>
                                    </form>
                                </div>
                                <div className="mt-3">
                                    <Link to={ROUTE_FORGOT_PASSWORD}>
                                        Passwort vergessen?
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </OutsideContainer>
    )
}

export default Login;