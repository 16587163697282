import React from 'react';
import {useTranslation} from "react-i18next";

function InputError({error}) {
    const {t} = useTranslation();
    return error ? (
        <div className="help-block text-danger">
            {t(`errors.default.${error}`)}
        </div>
    ) : null
}

export default InputError;