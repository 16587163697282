import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import moment from "moment";
import ApplyForProject from "./ApplyForProject";
import {useTranslation} from "react-i18next";
import UpdateProjectPerson from "./UpdateProjectPerson";

function Project({projectId}) {
    let availableProjects = useSelector(state => state.person.availableProjects);
    let person = useSelector(state => state.person.person);
    const {t} = useTranslation();

    const [project, setProject] = useState(null);

    useEffect(() => {
        if (availableProjects && projectId) {
            setProject(availableProjects.find(el => el.id === projectId));
        }
    }, [availableProjects, projectId]);

    return project ? (
        <div className="Project card text-white bg-secondary mb-2">
            <div className="card-header">
                {project.startAt && project.endAt && (
                    <div className="float-right">
                        {moment(project.startAt).format("DD.MM.")} - {moment(project.endAt).format("DD.MM.YYYY")}
                    </div>
                )}
                <h5 className="card-title mb-0">
                    {project.displayName}
                </h5>
            </div>
            <div className="card-body">
                {project.projectPerson?.shifts?.length > 0 ? (
                    <div className="alert alert-primary">
                      <b>Deine Schichten</b><br/><br/>
                      {project.projectPerson?.shifts?.map((shift, sI) => (
                        <div key={sI}>
                          {moment(shift.startAt).format('DD.MM., HH:mm')}{" "}-{" "}{moment(shift.endAt).format('HH:mm')}: {shift.locationName}
                        </div>
                      ))}
                    </div>
                ) : null}
                <div style={{whiteSpace: 'pre-wrap'}}>{project.infoText}</div>
                <br/>
                {true /*person.status === 'CONFIRMED' && person.personDataStatus === 'COMPLETE'*/ && (
                    project.projectPerson ? (
                        <div className="alert alert-dark mb-0">
                          <div className="float-right">
                            <UpdateProjectPerson project={project}/>
                          </div>
                          {t(`frontend.projectPerson.status.${project.projectPerson.status}`)}
                          {(project.projectPerson.plannedGettingThere || project.projectPerson.plannedShiftAmount) ? (
                            <div className="mt-2">
                              {t('frontend.projectPerson.plannedGettingThere', 'Geplante Anreise')}: <b>{project.projectPerson.plannedGettingThere ? moment(project.projectPerson.plannedGettingThere).format('DD.MM.YYYY') : ''}</b><br/>
                              {t('frontend.projectPerson.plannedDeparture', 'Geplante Abreise')}: <b>{project.projectPerson.plannedDeparture ? moment(project.projectPerson.plannedDeparture).format('DD.MM.YYYY') : ''}</b><br/>
                              {t('frontend.projectPerson.plannedWorkingHours', 'Gewünschte Arbeitszeit')}: <b>{project.projectPerson.plannedWorkingHours ? project.projectPerson.plannedWorkingHours + ' Stunden' : ''}</b>
                              {project.projectPerson.comment && (
                                <div>
                                  {t('frontend.projectPerson.comment', "Kommentar")}: <b>{project.projectPerson.comment}</b>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="mt-2 text-danger">
                              Bitte vervollständige deine Daten. Klicke dafür in diesem Kasten oben rechts auf "bearbeiten".
                            </div>
                          )}
                        </div>
                    ) : (
                        <ApplyForProject project={project}/>
                    )
                )}
            </div>
        </div>
    ) : null
}

export default Project;