import React from 'react';
import moment from 'moment';

function NewsEntry({newsEntry}) {
    return (
        <div className="NewsEntry mb-3">
            <b>{newsEntry.title}</b>
            <small className="mt-n1 d-block">{moment(newsEntry.createdAt).format('DD.MM.YY HH:mm')}</small>
            <div style={{whiteSpace: 'pre-wrap'}}>
                {newsEntry.message}
            </div>
        </div>
    )
}

export default NewsEntry;