import React, {Fragment, useState} from 'react';
import ProjectPersonApi from "../../api/ProjectPersonApi";
import {useDispatch} from "react-redux";
import {personLoadSuccess} from "../../store/person/personAction";
import {Modal} from "react-bootstrap";
import DatePickerInput from "../../components/forms/DatePickerInput";
import moment from "moment";
import SelectInput from "../../components/forms/SelectInput";
import TextArea from "../../components/forms/TextArea";
import Button from "../../components/forms/Button";

const UpdateProjectPerson = ({project}) => {
  const dispatch = useDispatch();

  const defaultFormValues = {
    plannedGettingThere: project.projectPerson?.plannedGettingThere ? moment(project.projectPerson?.plannedGettingThere) : null,
    plannedDeparture: project.projectPerson?.plannedDeparture ? moment(project.projectPerson?.plannedDeparture) : null,
    plannedWorkingHours: project.projectPerson?.plannedWorkingHours ? project.projectPerson?.plannedWorkingHours : 24,
    comment: project.projectPerson?.comment,
  };

  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setErrors({});
    setFormValues(defaultFormValues);
    setLoading(false);
    setOpen(true);
  }

  const setFormValue = (key, value) => {
    setFormValues(fv => ({...fv, [key]: value}));
  }

  const handleSubmit = (e) => {
    e?.preventDefault();
    setLoading(true);
    ProjectPersonApi.update(project?.id, formValues.plannedGettingThere, formValues.plannedDeparture, formValues.plannedWorkingHours, formValues.comment)
      .then(response => {
        setLoading(false);
        if (response.data.success) {
          dispatch(personLoadSuccess(response.data.data));
          setOpen(false);
        } else {
          setErrors(response.data.errors);
        }
      })
  }

  return (
    <Fragment>
      <button className="btn btn-link p-0" onClick={handleOpen}>
        bearbeiten
      </button>
      <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {project.displayName}
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="row">
              <div className="col-6">
                <DatePickerInput label="Mögliche Anreise"
                                 dateFormat="DD.MM.YYYY"
                                 timeFormat={false}
                                 value={formValues.plannedGettingThere}
                                 onChange={val => setFormValue('plannedGettingThere', val)}
                                 initialViewDate={moment(project.startAt)}
                                 error={errors?.plannedGettingThere}
                                 closeOnSelect/>
              </div>
              <div className="col-6">
                <DatePickerInput label="Mögliche Abreise"
                                 dateFormat="DD.MM.YYYY"
                                 timeFormat={false}
                                 value={formValues.plannedDeparture}
                                 onChange={val => setFormValue('plannedDeparture', val)}
                                 initialViewDate={moment(project.startAt)}
                                 error={errors?.plannedDeparture}
                                 closeOnSelect/>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <SelectInput name="plannedWorkingHours" value={formValues.plannedWorkingHours} onChange={val => setFormValue('plannedWorkingHours', val)}
                             error={errors?.plannedWorkingHours} label="Gewünschte Arbeitszeit" options={[
                  {
                    value: 24,
                    label: '24 Stunden'
                  },
                  {
                    value: 30,
                    label: '30 Stunden'
                  },
                  {
                    value: 36,
                    label: '36 Stunden'
                  },
                  {
                    value: 42,
                    label: '42 Stunden'
                  },
                  {
                    value: 48,
                    label: '48 Stunden'
                  },
                ]}/>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <TextArea name="comment" value={formValues.comment}
                          onChange={val => setFormValue('comment', val)}
                          error={errors?.comment}
                          label="Kommentar"/>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={() => setOpen(false)} color="secondary" label="Abbrechen"/>
            <Button type="submit" color="primary" label="Speichern" loading={loading}/>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  )
}

export default UpdateProjectPerson;