import React from 'react';
import {ROUTE_DATA_PROTECTION, ROUTE_IMPRINT} from "../../config/routes";
import {Link} from "react-router-dom";

function Footer() {
    return (
        <div className="container">
            <div className="row pb-3 mt-3">
                <div className="col-12 text-center">
                    <Link to={ROUTE_IMPRINT}>Impressum</Link>
                    <div className="pl-2 pr-2 d-inline-block"/>
                    <Link to={ROUTE_DATA_PROTECTION}>Datenschutz</Link>
                </div>
            </div>
        </div>
    )
}

export default Footer;