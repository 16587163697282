import axios from 'axios';

class UserApi {
    static register = (email, password, token, acceptDataProtection) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/api/frontend/user/register`, {
            email, password, token, acceptDataProtection
        })
    }

    static activate = (token) => {
        return axios.put(`${process.env.REACT_APP_API_URL}/api/frontend/user/activate`, {
            token
        })
    }

    static forgotPassword = (email) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/api/frontend/user/forgotPassword`, {
            username: email
        })
    }

    static resetPassword = (token, password) => {
        return axios.put(`${process.env.REACT_APP_API_URL}/api/frontend/user/resetPassword`, {
            token, password
        })
    }
}

export default UserApi;