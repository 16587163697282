import React from 'react';

function OutsideContainer({children}) {
  return (
    <div className="OutsideContainer">
      <div className="container">
        {children}
      </div>
    </div>
  )
}

export default OutsideContainer;