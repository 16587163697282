import React, {useEffect, useState} from 'react';
import moment from 'moment';
import TextInput from "../../components/forms/TextInput";
import DatePickerInput from "../../components/forms/DatePickerInput";
import RadioGroup from "../../components/forms/RadioGroup";
import SelectInput from "../../components/forms/SelectInput";
import Button from "../../components/forms/Button";
import {useTranslation} from "react-i18next";
import OtherEmployment from "./OtherEmployment";
import Checkbox from "../../components/forms/Checkbox";
import PersonApi from "../../api/PersonApi";
import SelectFileBox from "../../components/forms/SelectFileBox";
import {useSelector} from "react-redux";

function PersonalData() {
    const {t} = useTranslation();
    let person = useSelector(state => state.person.person);

    useEffect(() => {
        if (person) {
            if (person.lastName) setLastName(person.lastName);
            if (person.firstName) setFirstName(person.firstName);
            if (person.phone) setPhone(person.phone);
            if (person.fusionCrew) setFusionCrew(person.fusionCrew);

            PersonApi.getLastPersonalData()
                .then(response => {
                    if (response.data.data) {
                        let data = response.data.data;
                        setOtherEmployments(data.otherEmployments.map((oe, oeI) => {
                            return {
                                ...oe,
                                tid: oeI,
                                start: moment(oe.start),
                                end: moment(oe.end)
                            }
                        }));
                        if (data.personalData.street) setStreet(data.personalData.street);
                        if (data.personalData.city) setCity(data.personalData.city);
                        if (data.personalData.changedResidence !== null) setChangedResidence(data.personalData.changedResidence ? 'true' : 'false');
                        if (data.personalData.changedResidenceDocument) setChangedResidenceDocument(data.personalData.changedResidenceDocument?.id);
                        if (data.personalData.birthday) setBirthday(moment(data.personalData.birthday));
                        if (data.personalData.gender) setGender(data.personalData.gender);
                        if (data.personalData.insuranceNumber) setInsuranceNumber(data.personalData.insuranceNumber);
                        if (data.personalData.maritalStatus) setMaritalStatus(data.personalData.maritalStatus);
                        if (data.personalData.placeOfBirth) setPlaceOfBirth(data.personalData.placeOfBirth);
                        if (data.personalData.severelyDisabled) setSeverelyDisabled(data.personalData.severelyDisabled);
                        if (data.personalData.nationality) setNationality(data.personalData.nationality);
                        if (data.personalData.taxIdentificationNumber) setTaxIdentificationNumber(data.personalData.taxIdentificationNumber);
                        if (data.personalData.iban) setIban(data.personalData.iban);
                        if (data.personalData.bic) setBic(data.personalData.bic);
                        if (data.personalData.passportNumber) setPassportNumber(data.personalData.passportNumber);
                        if (data.personalData.entryDate) setEntryDate(moment(data.personalData.entryDate));
                        if (data.personalData.firstEntryDate) setFirstEntryDate(moment(data.personalData.firstEntryDate));
                        if (data.personalData.permanentEstablishment) setPermanentEstablishment(data.personalData.permanentEstablishment);
                        if (data.personalData.jobTitle) setJobTitle(data.personalData.jobTitle);
                        if (data.personalData.activityPerformed) setActivityPerformed(data.personalData.activityPerformed);
                        if (data.personalData.graduation) setGraduation(data.personalData.graduation);
                        if (data.personalData.apprenticeship) setApprenticeship(data.personalData.apprenticeship);
                        if (data.personalData.stateAtJobStart) setStateAtJobStart(data.personalData.stateAtJobStart);
                        setUnemployed(data.personalData.unemployed ? 'yes' : 'no');
                        if (data.personalData.unemployedAt) setUnemployedAt(data.personalData.unemployedAt);
                        setUnemployedWithServices(data.personalData.unemployedWithServices ? 'yes' : 'no')
                        if (data.personalData.healthInsuranceType) setHealthInsuranceType(data.personalData.healthInsuranceType);
                        if (data.personalData.healthInsuranceName) setHealthInsuranceName(data.personalData.healthInsuranceName);
                        if (data.personalData.rvContributions !== null) setRvContributions(data.personalData.rvContributions ? 'true' : 'false');
                        if (data.personalData.passportFrontDocument) setPassportFrontDocument(data.personalData.passportFrontDocument?.id);
                        if (data.personalData.passportBackDocument) setPassportBackDocument(data.personalData.passportBackDocument?.id);
                        if (data.personalData.certificate34aDocument) setCertificate34aDocument(data.personalData.certificate34aDocument?.id);
                        if (data.personalData.securityRegisterNumber) setSecurityRegisterNumber(data.personalData.securityRegisterNumber);
                        if (data.personalData.trueData) setTrueData(data.personalData.trueData);
                        setPersonalData(data.personalData);
                    }
                })
        }
    }, [person]);


    const [otherEmployments, setOtherEmployments] = useState([]);

    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [phone, setPhone] = useState('');
    const [fusionCrew, setFusionCrew] = useState('');
    const [changedResidence, setChangedResidence] = useState('');
    const [changedResidenceDocument, setChangedResidenceDocument] = useState(null);
    const [birthday, setBirthday] = useState('');
    const [gender, setGender] = useState('');
    const [insuranceNumber, setInsuranceNumber] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');
    const [severelyDisabled, setSeverelyDisabled] = useState('');
    const [nationality, setNationality] = useState('');
    const [taxIdentificationNumber, setTaxIdentificationNumber] = useState('');
    const [iban, setIban] = useState('');
    const [bic, setBic] = useState('');
    const [passportNumber, setPassportNumber] = useState('');
    const [entryDate, setEntryDate] = useState('');
    const [firstEntryDate, setFirstEntryDate] = useState('');
    const [permanentEstablishment, setPermanentEstablishment] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [activityPerformed, setActivityPerformed] = useState('');
    const [graduation, setGraduation] = useState('ohneSchulabschluss');
    const [apprenticeship, setApprenticeship] = useState('ohne');
    const [stateAtJobStart, setStateAtJobStart] = useState('arbeitnehmer');
    const [unemployed, setUnemployed] = useState(null);
    const [unemployedAt, setUnemployedAt] = useState('');
    const [unemployedWithServices, setUnemployedWithServices] = useState(null);
    const [healthInsuranceType, setHealthInsuranceType] = useState('');
    const [healthInsuranceName, setHealthInsuranceName] = useState('');
    const [rvContributions, setRvContributions] = useState(null);
    const [passportFrontDocument, setPassportFrontDocument] = useState(null);
    const [passportBackDocument, setPassportBackDocument] = useState(null);
    const [certificate34aDocument, setCertificate34aDocument] = useState(null);
    const [securityRegisterNumber, setSecurityRegisterNumber] = useState('');
    const [trueData, setTrueData] = useState('');
    const [personalData, setPersonalData] = useState(null);

    const handleAddOtherEmployment = () => {
        setOtherEmployments([
            ...otherEmployments,
            {
                tid: Math.max(...[0, ...otherEmployments.map(el => el.tid)]) + 1
            }
        ])
    };

    const handleDeleteOtherEmployment = (tid) => {
        setOtherEmployments(otherEmployments.filter(el => el.tid !== tid));
    }

    const handleChangeOtherEmploymentValue = (tid, property, value) => {
        setOtherEmployments(otherEmployments.map(oe => {
            if (oe.tid === tid) {
                return {
                    ...oe,
                    [property]: value
                }
            } else {
                return oe;
            }
        }));
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        PersonApi.savePersonalData({
            lastName,
            firstName,
            street,
            city,
            phone,
            fusionCrew,
            changedResidence,
            changedResidenceDocument,
            birthday,
            gender,
            insuranceNumber,
            maritalStatus,
            placeOfBirth,
            severelyDisabled,
            nationality,
            taxIdentificationNumber,
            iban,
            bic,
            passportNumber,
            entryDate,
            firstEntryDate,
            permanentEstablishment,
            jobTitle,
            activityPerformed,
            graduation,
            apprenticeship,
            stateAtJobStart,
            unemployed,
            unemployedAt,
            unemployedWithServices,
            healthInsuranceType,
            healthInsuranceName,
            rvContributions,
            passportFrontDocument,
            passportBackDocument,
            certificate34aDocument,
            securityRegisterNumber,
            trueData,
            otherEmployments
        }).then(response => {
            window.location.href = "/";
        })
    }

    const handleSubmitPersonalData = () => {
        PersonApi.submitPersonalData({
            lastName,
            firstName,
            street,
            city,
            phone,
            fusionCrew,
            changedResidence,
            changedResidenceDocument,
            birthday,
            gender,
            insuranceNumber,
            maritalStatus,
            placeOfBirth,
            severelyDisabled,
            nationality,
            taxIdentificationNumber,
            iban,
            bic,
            passportNumber,
            entryDate,
            firstEntryDate,
            permanentEstablishment,
            jobTitle,
            activityPerformed,
            graduation,
            apprenticeship,
            stateAtJobStart,
            unemployed,
            unemployedAt,
            unemployedWithServices,
            healthInsuranceType,
            healthInsuranceName,
            rvContributions,
            passportFrontDocument,
            passportBackDocument,
            certificate34aDocument,
            securityRegisterNumber,
            trueData,
            otherEmployments
        }).then(response => {
            window.location.href = "/";
        })
    }

    return (
        <div className="PersonalData">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="mb-0">
                                {t('personalData.title', 'Deine Daten')}
                            </h5>
                        </div>
                        <div className="card-body">
                            Da es sich um ein Anstellungsverhältnis handelt, benötigen wir einige
                            Daten von dir.
                            Bitte gib diese hier an, um deinen Account in vollem Umfang zu nutzen.<br/>
                            Selbstverständlich schützen wir deine Daten bestmöglich!
                            {personalData?.declinedMessage && (
                                <div className="mt-5">
                                    <div className="alert alert-info">
                                        {personalData.declinedMessage}
                                    </div>
                                </div>
                            )}
                            <div className="mt-5">
                                <form onSubmit={handleFormSubmit}>
                                    <h5>
                                        {t('personalData.personalData.title', 'Persönliche Angaben')}
                                    </h5>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextInput name="lastName" label="Familienname" onChange={val => setLastName(val)}
                                                       helpText="ggf. Geburtsname" value={lastName}/>
                                        </div>
                                        <div className="col-6">
                                            <TextInput name="firstName" label="Vorname" value={firstName}
                                                       onChange={val => setFirstName(val)}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextInput name="street" label="Straße und Hausnummer"
                                                       helpText="inkl. Anschriftenzusatz" value={street}
                                                       onChange={val => setStreet(val)}/>
                                        </div>
                                        <div className="col-6">
                                            <TextInput name="city" label="PLZ, Ort" value={city}
                                                       onChange={val => setCity(val)}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextInput name="phone" label="Telefonnummer"
                                                       value={phone}
                                                       onChange={val => setPhone(val)}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextInput name="securityRegisterNumber"
                                                       label="Bewachungsregisternummer"
                                                       helpText="Falls du schon im Bewachungsregister eingetragen bist."
                                                       value={securityRegisterNumber}
                                                       onChange={val => setSecurityRegisterNumber(val)}/>
                                        </div>
                                        <div className="col-6">
                                            <TextInput name="fusionCrew"
                                                       label="Fusion-Crew"
                                                       helpText="Wichtig -> Bist du Teil einer festen Fusion-Crew? Bitte den Namen deiner Crew eintragen. Falls du bisher in keiner Crew tätig warst bitte KuKo-Security angeben!"
                                                       value={fusionCrew}
                                                       onChange={val => setFusionCrew(val)}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <RadioGroup name="changedResidence" value={changedResidence}
                                                        onChange={val => setChangedResidence(val)}
                                                        label="Hast Du innerhalb der letzten 5 Jahre Deinen Wohnort gewechselt?"
                                                        options={[{value: 'true', label: 'ja'}, {
                                                            value: 'false',
                                                            label: 'nein'
                                                        }]}
                                                        inline/>
                                        </div>
                                        {changedResidence === 'true' && (
                                            <div className="col-6">
                                                <SelectFileBox
                                                    label="Bitte erläutere in einem Dokument, wo du zu welchem Zeitpunkt in den letzten 5 Jahren gewohnt hast."
                                                    value={changedResidenceDocument}
                                                    onChange={val => setChangedResidenceDocument(val)}/>
                                            </div>
                                        )}
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <DatePickerInput label="Geburtsdatum" initialViewMode="years"
                                                             dateFormat="DD.MM.YYYY"
                                                             timeFormat={false}
                                                             value={birthday}
                                                             onChange={val => setBirthday(val)}
                                                             initialViewDate={moment().subtract(30, 'years')}
                                                             closeOnSelect/>
                                        </div>
                                        <div className="col-6">
                                            <RadioGroup name="gender" label="Geschlecht"
                                                        options={[{value: 'm', label: 'männlich'}, {
                                                            value: 'f',
                                                            label: 'weiblich'
                                                        }, {
                                                            value: 'd',
                                                            label: 'divers'
                                                        }]}
                                                        value={gender}
                                                        onChange={val => setGender(val)}
                                                        inline/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextInput name="insuranceNumber" label="Versicherungsnummer"
                                                       value={insuranceNumber} onChange={val => setInsuranceNumber(val)}
                                                       helpText="gem. Sozialvers.Ausweis"/>
                                        </div>
                                        <div className="col-6">
                                            <TextInput name="maritalStatus" label="Familienstand" value={maritalStatus}
                                                       onChange={val => setMaritalStatus(val)}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextInput name="placeOfBirth" label="Geburtsort, -land"
                                                       value={placeOfBirth} onChange={val => setPlaceOfBirth(val)}
                                                       helpText="nur bei fehlender Versicherungsnummer"/>
                                        </div>
                                        <div className="col-6">
                                            <RadioGroup name="severelyDisabled" label="Schwerbehindert"
                                                        value={severelyDisabled}
                                                        onChange={val => setSeverelyDisabled(val)}
                                                        options={[{value: 'yes', label: 'ja'}, {
                                                            value: 'no',
                                                            label: 'nein'
                                                        }]} inline/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextInput name="nationality" label="Staatsangehörigkeit"
                                                       value={nationality} onChange={val => setNationality(val)}/>
                                        </div>
                                        <div className="col-6">
                                            <TextInput name="taxIdentificationNumber" label="Steueridentifikationsnummer"
                                                       value={taxIdentificationNumber}
                                                       onChange={val => setTaxIdentificationNumber(val)}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextInput name="iban" label="IBAN" value={iban}
                                                       onChange={val => setIban(val)}/>
                                        </div>
                                        <div className="col-6">
                                            <TextInput name="bic" label="BIC" value={bic}
                                                       onChange={val => setBic(val)}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextInput name="passportNumber" label="Personalausweis Nr."
                                                       value={passportNumber} onChange={val => setPassportNumber(val)}/>
                                        </div>
                                    </div>
                                    <h5 className="mt-5">
                                        Beschäftigung
                                    </h5>
                                    <div className="row">
                                        <div className="col-6 col-md-3">
                                            <DatePickerInput label="Eintrittsdatum" initialViewMode="years"
                                                             name="entryDate"
                                                             value={entryDate}
                                                             onChange={val => setEntryDate(val)}
                                                             dateFormat="DD.MM.YYYY"
                                                             timeFormat={false}
                                                             initialViewDate={moment().subtract(2, 'years')}
                                                             closeOnSelect/>
                                        </div>
                                        <div className="col-6 col-md-3">
                                            <DatePickerInput label="Ersteintrittsdatum" initialViewMode="years"
                                                             name="firstEntryDate"
                                                             value={firstEntryDate}
                                                             onChange={val => setFirstEntryDate(val)}
                                                             dateFormat="DD.MM.YYYY"
                                                             timeFormat={false}
                                                             initialViewDate={moment().subtract(10, 'years')}
                                                             closeOnSelect/>
                                        </div>
                                        <div className="col-6">
                                            <TextInput name="permanentEstablishment" value={permanentEstablishment}
                                                       onChange={val => setPermanentEstablishment(val)}
                                                       label={t('personalData.permanentEstablishment', 'Betriebsstätte')}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextInput name="jobTitle" label="Berufsbezeichnung" value={jobTitle}
                                                       onChange={val => setJobTitle(val)}/>
                                        </div>
                                        <div className="col-6">
                                            <TextInput name="activityPerformed" label="Ausgeübte Tätigkeit"
                                                       value={activityPerformed}
                                                       onChange={val => setActivityPerformed(val)}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <SelectInput label="Höchster Schulabschluss" name="graduation"
                                                         value={graduation}
                                                         onChange={val => setGraduation(val)}
                                                         options={[{
                                                             value: 'ohneSchulabschluss',
                                                             label: 'Ohne Schulabschluss'
                                                         }, {
                                                             value: 'hauptVolksschulabschluss',
                                                             label: 'Haupt-/Volksschulabschluss'
                                                         }, {
                                                             value: 'mittlereReife',
                                                             label: 'Mittlere Reife/gleichwertiger Abschluss'
                                                         }, {
                                                             value: 'abitur',
                                                             label: 'Abitur/Fachabitur'
                                                         }]}/>
                                        </div>
                                        <div className="col-6">
                                            <SelectInput label="Höchste Berufsausbildung" name="apprenticeship"
                                                         value={apprenticeship}
                                                         onChange={val => setApprenticeship(val)}
                                                         options={[
                                                             {
                                                                 value: 'ohne',
                                                                 label: 'ohne beruflichen Ausbildungsabschluss'
                                                             },
                                                             {
                                                                 value: 'anerkannt',
                                                                 label: 'Anerkannte Berufsausbildung'
                                                             },
                                                             {
                                                                 value: 'meister',
                                                                 label: 'Meister/Techniker/gleichwertiger Fachschulabschluss'
                                                             },
                                                             {
                                                                 value: 'bachelor',
                                                                 label: 'Bachelor'
                                                             },
                                                             {
                                                                 value: 'diplom',
                                                                 label: 'Diplom/Magister/Master/Staatsexamen'
                                                             },
                                                             {
                                                                 value: 'promotion',
                                                                 label: 'Promotion'
                                                             }
                                                         ]}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <SelectInput label="Status bei Beginn der Beschäftigung"
                                                         value={stateAtJobStart}
                                                         onChange={val => setStateAtJobStart(val)}
                                                         name="stateAtJobStart" options={[
                                                {
                                                    value: 'arbeitnehmer',
                                                    label: 'Arbeitnehmer/in'
                                                },
                                                {
                                                    value: 'beamter',
                                                    label: 'Beamtin/Beamter'
                                                },
                                                {
                                                    value: 'schulentlassener',
                                                    label: 'Schulentlassene/r'
                                                },
                                                {
                                                    value: 'sozialhilfe',
                                                    label: 'Sozialhilfeempfänger/in'
                                                },
                                                {
                                                    value: 'elternzeit',
                                                    label: 'Arbeitnehmer/in in Elternzeit'
                                                },
                                                {
                                                    value: 'hausmann',
                                                    label: 'Hausfrau/Hausmann'
                                                },
                                                {
                                                    value: 'selbststaendig',
                                                    label: 'Selbstständige/r'
                                                },
                                                {
                                                    value: 'studienbewerber',
                                                    label: 'Studienbewerber/in'
                                                },
                                                {
                                                    value: 'arbeitsloser',
                                                    label: 'Arbeitslose/r'
                                                },
                                                {
                                                    value: 'schueler',
                                                    label: 'Schüler/in'
                                                },
                                                {
                                                    value: 'student',
                                                    label: 'Student/in'
                                                },
                                                {
                                                    value: 'zivi',
                                                    label: 'Wehr-/Zivildienstleistender'
                                                },
                                                {
                                                    value: 'other',
                                                    label: 'Sonstiges'
                                                }
                                            ]}/>
                                        </div>
                                    </div>
                                    <h5 className="mt-5">
                                        Angaben über die Meldung als Arbeit- oder Ausbildungsuchender
                                    </h5>
                                    <p>
                                        Bist du zu Beginn des Beschäftigungsverhältnisses beschäftigungslos und bei der Agentur für Arbeit arbeit- oder ausbildungsuchend gemeldet?
                                    </p>
                                    <div className="row">
                                        <div className="col-12">
                                            <RadioGroup name="unemployed"
                                                        value={unemployed}
                                                        onChange={val => setUnemployed(val)}
                                                        options={[{value: 'yes', label: 'ja'}, {
                                                            value: 'no',
                                                            label: 'nein'
                                                        }]} inline/>
                                        </div>
                                        {unemployed === 'yes' && (
                                          <div className="col-6">
                                              <TextInput name="unemployedAt" value={unemployedAt}
                                                         onChange={val => setUnemployedAt(val)}
                                                         label="Agentur für Arbeit"/>
                                          </div>
                                        )}
                                        {unemployed === 'yes' && (
                                          <div className="col-6">
                                              <RadioGroup name="unemployedWithServices"
                                                          value={unemployedWithServices}
                                                          onChange={val => setUnemployedWithServices(val)}
                                                          options={[{value: 'yes', label: 'mit Leistungsbezug'}, {
                                                              value: 'no',
                                                              label: 'ohne Leistungsbezug'
                                                          }]}/>
                                          </div>
                                        )}
                                    </div>
                                    <h5 className="mt-5">
                                        Sozialversicherung
                                    </h5>
                                    <div className="row">
                                        <div className="col-6">
                                            <RadioGroup name="healthInsuranceType" label="Krankenversicherung"
                                                        value={healthInsuranceType}
                                                        onChange={val => setHealthInsuranceType(val)}
                                                        options={[{
                                                            value: 'legally',
                                                            label: 'Gesetzlich'
                                                        }, {value: 'private', label: 'Privat'}]} inline/>
                                        </div>
                                        <div className="col-6">
                                            <TextInput name="healthInsuranceName" value={healthInsuranceName}
                                                       onChange={val => setHealthInsuranceName(val)}
                                                       label="Name Krankenkasse/Priv. Versicherung"/>
                                        </div>
                                    </div>
                                    <h6 className="mt-2">
                                        Nur bei geringfügig Beschäftigten:
                                    </h6>
                                    <div className="row">
                                        <div className="col-12">
                                            <RadioGroup name="rvContributions"
                                                        value={rvContributions}
                                                        onChange={val => setRvContributions(val)}
                                                        label="Option für die Aufstockung der RV-Beiträge (§ 5 Abs. 2 Satz 2 SGB VI)"
                                                        options={[{
                                                            value: 'false',
                                                            label: 'Verzicht auf die RV-Option'
                                                        }, {
                                                            value: 'true',
                                                            label: 'Ausübung der RV-Option (Verzicht auf RV-Freiheit)'
                                                        }]}
                                            />
                                        </div>
                                    </div>
                                    <h5 className="mt-5">
                                        Angaben zu weiteren Beschäftigungen
                                    </h5>
                                    <p>
                                        (bei kurzfristig Beschäftigten auch zu Vorbeschäftigungen aus dem Vorjahr)
                                    </p>
                                    {otherEmployments.map((oe, oeI) => (
                                        <OtherEmployment index={oeI} tid={oe.tid} key={oeI} values={oe}
                                                         setValue={(prop, val) => handleChangeOtherEmploymentValue(oe.tid, prop, val)}
                                                         onDelete={handleDeleteOtherEmployment}/>
                                    ))}
                                    <div className="row">
                                        <div className="col-12">
                                            <button type="button" className="btn btn-secondary"
                                                    onClick={handleAddOtherEmployment}>
                                                Beschäftigung hinzufügen
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <div className="row">
                                            <div className="col-12">
                                                <h5>
                                                    Dokumente
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <SelectFileBox onChange={val => setPassportFrontDocument(val)}
                                                               value={passportFrontDocument}
                                                               label="Ausweis (Vorderseite)"/>
                                            </div>
                                            <div className="col-6">
                                                <SelectFileBox onChange={val => setPassportBackDocument(val)}
                                                               value={passportBackDocument}
                                                               label="Ausweis (Rückseite)"/>
                                            </div>
                                            <div className="col-6">
                                                <SelectFileBox onChange={val => setCertificate34aDocument(val)}
                                                               value={certificate34aDocument}
                                                               label="34a Nachweis (Zertifikat)"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <div className="row">
                                            <div className="col-12">
                                                <h5>
                                                    Erklärung des Arbeitnehmers
                                                </h5>
                                                <Checkbox name="trueData"
                                                          value={trueData} onChange={val => setTrueData(val)}
                                                          label="Ich versichere, dass die vorstehenden Angaben der Wahrheit entsprechen. Ich verpflichte mich, meinem Arbeitgeber alle Änderungen, insbesondere in Bezug auf weitere Beschäftigungen (in Bezug auf Art, Dauer und Entgelt) unverzüglich mitzuteilen."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <Button label="Daten speichern und später weiter bearbeiten"/>
                                    </div>
                                    <div className="mt-3">
                                        <Button type="button" color="success" label="Daten einreichen" onClick={handleSubmitPersonalData}/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PersonalData;