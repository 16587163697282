import React from 'react';
import {useTranslation} from "react-i18next";
import moment from "moment";
import DatePickerInput from "../../components/forms/DatePickerInput";
import TextInput from "../../components/forms/TextInput";
import SelectInput from "../../components/forms/SelectInput";

function OtherEmployment({index, values, setValue, onDelete, tid}) {
    const {t} = useTranslation();
    return (
        <div className="OtherEmployment mb-3">
            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-link float-right" onClick={() => onDelete(tid)}>
                        entfernen
                    </button>
                    <h6>
                        {t('personalData.otherEmployment.title', 'Weitere Beschäftigung {{index1}}', {index1: index + 1})}
                    </h6>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <TextInput name="company" value={values?.company} onChange={val => setValue('company', val)}
                               label={t('personalData.otherEmployment.company', 'Arbeitgeber')}/>
                </div>
                <div className="col-6 col-md-3">
                    <DatePickerInput label={t('personalData.otherEmployment.start', 'Von')} initialViewMode="years"
                                     value={values?.start}
                                     dateFormat="DD.MM.YYYY"
                                     timeFormat={false}
                                     initialViewDate={moment().subtract(1, 'years')}
                                     onChange={val => setValue('start', val)}
                                     closeOnSelect/>
                </div>
                <div className="col-6 col-md-3">
                    <DatePickerInput label={t('personalData.otherEmployment.end', 'Bis')} initialViewMode="years"
                                     value={values?.end}
                                     dateFormat="DD.MM.YYYY"
                                     timeFormat={false}
                                     initialViewDate={moment().subtract(8, 'months')}
                                     onChange={val => setValue('end', val)}
                                     closeOnSelect/>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <SelectInput label="Art der Tätigkeit" name="employmentType"
                                 value={values?.employmentType}
                                 onChange={val => setValue('employmentType', val)}
                                 options={[{
                                     value: 'geringfuegig',
                                     label: 'geringfügig entlohnt'
                                 }, {
                                     value: 'nichtGeringfuegig',
                                     label: 'nicht geringfügig entlohnt'
                                 }, {
                                     value: 'kurzfristig',
                                     label: 'kurzfristig beschäftigt'
                                 }]}/>
                </div>
                <div className="col-6">
                    <TextInput name="weeklyWorkTime" label="Wöchentliche Arbeitszeit" value={values?.weeklyWorkTime} onChange={val => setValue('weeklyWorkTime', val)}/>
                </div>
            </div>
        </div>
    )
}

export default OtherEmployment;