import React from 'react';

function DataProtection() {
    return (
        <div className="container mt-5 flex-fill">
            <div className="row">
                <div className="col-12">
                    <h2>Datenschutzerklärung</h2>
                    <i>Version 1.0 vom 02.07.2021</i><br/>
                    <br/>
                    <br/>
                    Für den Schutz deiner personenbezogenen Daten sind wir sensibilisiert.<br/>
                    Mit dieser Datenschutzerklärung bieten wir Euch alle notwendigen Hinweise für eine
                    informierte Nutzung der Webseite.<br/>
                    <br/>
                    Wir verarbeiten deine personenbezogenen Daten, weil Du dich für eine Beschäftigung
                    als Sicherheitskraft für die Firma Kuko Security GmbH beworben bzw. registrierst hast.<br/>
                    Soweit die Registrierung erfolgreich war, verarbeiten wir deine personenbezogenen
                    Daten im Rahmen des dann entstehenden Arbeitsverhältnisses.<br/>
                    <br/>
                    Für die Registrierung und ferner auch für die Organisation eines folgenden
                    Arbeitsverhältnisses halten wir eine eigene Webanwendung unter
                    account.kuko-security.de bereit.<br/>
                    <br/>
                    <br/>
                    <b>Für dieses Webangebot ist im datenschutzrechtlichen Sinne Verantwortlicher:</b><br/>
                    <br/>
                    Kuko Security GmbH<br/>
                    Am Flugplatz<br/>
                    17248 Lärz<br/>
                    <br/>
                    Telefon: 039833 2740-0<br/>
                    E-Mail: mail@kuko-security.de<br/>
                    <br/>
                    <br/>
                    <b>1. Sicherheit und Schutz der personenbezogenen Daten</b><br/>
                    Wir wahren die Vertraulichkeit der von Euch bei der Nutzung der Webseite und seiner
                    Funktionen bereitgestellten personenbezogenen Daten und schützen diese vor
                    unbefugten Zugriffen.<br/>
                    <br/>
                    Dieses Webangebots unterliegt den Bestimmungen der europäischen
                    Datenschutzgrundverordnung (DSGVO), des Telemediengesetzes (TMG) und den
                    Regelungen des Bundesdatenschutzgesetzes (BDSG).<br/>
                    <br/>
                    Wir verarbeiten Daten nur, wenn dies gesetzlich erlaubt ist bzw. die Nutzer:nnen in die
                    Datenerhebung ausdrücklich einwilligen.<br/>
                    Außerdem wenden wir große Sorgfalt und aktuelle Sicherheitsstandards an, um ein
                    hohes Schutzniveau zu gewährleisten. Hierzu haben wir auch technische und
                    organisatorische Maßnahmen getroffen, die sicherstellen, dass die Einhaltung der
                    Vorschriften über den Datenschutz gewährleistet werden kann.<br/>
                    <br/>
                    <br/>
                    <b>2. Information über die möglichen Datenverarbeitungen</b><br/>
                    Im Folgenden informieren wir über die möglichen Datenverarbeitungsprozesse.<br/>
                    <br/>
                    <b>2.1. Verarbeitung personenbezogener Daten bei Aufruf unserer Webanwendung</b><br/>
                    Bei bloßem Aufruf unserer Webanwendung, erheben wir die folgenden
                    personenbezogenen Daten, die dein Browser an unseren Server übermittelt:<br/>
                    • IP-Adresse<br/>
                    • Datum und Uhrzeit der Anfrage<br/>
                    • Zeitzonendifferenz zur Greenwich Mean Time (GMT)<br/>
                    • Inhalt der Anforderung (konkrete Seite)<br/>
                    • Zugriffsstatus/HTTP-Statuscode<br/>
                    • jeweils übertragene Datenmenge<br/>
                    • Website, von der die Anforderung kommt<br/>
                    • Browser<br/>
                    • Betriebssystem und dessen Oberfläche<br/>
                    • Sprache und Version der Browsersoftware.<br/>
                    <br/>
                    Wir verarbeiten den HTTP-Request soweit er erforderlich ist, um die Seite zu
                    übermitteln. Der Zweck der Datenverarbeitung liegt im Normalbetrieb in der
                    Bereitstellung öffentlicher Information. Die Verarbeitung dieser Daten ist technisch
                    erforderlich sind, um euch unser Webangebot anzuzeigen und die Stabilität und
                    Sicherheit zu gewährleisten. Die Rechtsgrundlage der Verarbeitung liegt in unserem
                    überwiegenden berechtigten Interesse nach Art. 6 Abs. 1 lit. f) DS-GVO.
                    Die hierbei erhobenen personenbezogenen Daten werden regelmäßig spätestens nach 7
                    Tagen gelöscht, es sei denn es gibt eine gesetzliche Pflicht zur längeren Aufbewahrung.<br/>
                    <br/>
                    Diese Webseite verwendet dabei Cookies. Cookies sind kleine Textdateien, die auf Ihrem
                    Endgerät gespeichert und ausgelesen werden können. Man unterscheidet Session-
                    Cookies, die wieder gelöscht werden, sobald Sie Ihren Browser schließen, und
                    permanenten Cookies, die über die einzelne Sitzung hinaus gespeichert werden.
                    Auf unseren Webseiten setzen wir Cookies ein, die für den Betrieb unseres
                    Webangebots technisch notwendig sind. Diese Cookies enthalten lediglich
                    Informationen zu bestimmten Einstellungen und sind nicht personenbezogen. Sie
                    können auch erforderlich sein, um die Benutzerführung zu erleichtern und die
                    Sicherheit der Seite zu gewährleisten.<br/>
                    Wir nutzen diese Cookies aufgrund unseres berechtigten Interesses gemäß Art. 6 Abs. 1
                    lit. f DS-GVO, um die Funktionalität unserer Webseite zu gewährleisten.<br/>
                    <br/>
                    <b>2.2. Anlegen eines Accounts</b><br/>
                    Wir laden interessierte Personen per E-Mail dazu ein einen Account bei unserer
                    Webanwendung anzulegen. Die für die Registrierung benötigten Daten können nur über
                    den zu erstellenden Account eingegeben werden.<br/>
                    Zur Account-Erstellung benötigen wir deine E-Mail-Adresse und ein von dir gewähltes
                    Pseudonym. Zur Aktivierung deines Accounts musst du eine Identität durch ein Double-
                    Opt-In-Verfahren bestätigen. Dazu erhältst du eine E-Mail mit einem Bestätigungslink an
                    die von dir angegebene E-Mail-Adresse. Betätigst du den Link und sind die Angaben zur
                    Registrierung erfolgreich geprüft worden, ist der Account aktiviert.<br/>
                    <br/>
                    Die Rechtsgrundlage für die Datenverarbeitung ist in dem Beschäftigungsverhältnis zu
                    sehen, Art. 6 Abs. 1 lit. b DS-GVO bzw. § 26 BDSG.<br/>
                    <br/>
                    Die Daten werden gelöscht, wenn du den Account löschst bzw. das
                    Beschäftigungsverhältnis endgültig beendet ist.<br/>
                    <br/>
                    <b>2.3. Registrierung</b><br/>
                    Wir verarbeiten deine zur Registrierung als Sicherheitskraft angegebenen
                    personenbezogenen Daten zum Zwecke der Begründung eines
                    Beschäftigungsverhältnisses. Dazu ist die Eintragung ins Bewachungsregister zwingend
                    erforderlich.<br/>
                    Für die Eintragung ins Bewachungsregister musst du uns auf dem elektronischen Wege
                    die nachfolgend erläuterten Daten über unser Webformular übermitteln. Im Rahmen
                    der Registrierung musst du nur diejenigen personenbezogenen Daten bereitstellen, die
                    für die für das Registrierungsverfahrens erforderlich sind oder zu deren Erhebung wir
                    gesetzlich verpflichtet sind.<br/>
                    <br/>
                    <u>Hierzu zählen insbesondere folgende Daten:</u><br/>
                    Personalien (wie Name und Vorname, Wohnanschrift, Geburtstag und -ort, Geschlecht,
                    Familienstand und Staatsangehörigkeit), Wohnortwechsel der letzten fünf Jahre,
                    Versicherungsnummer, Arbeitnehmernummer, Personalausweisnummer, zuständiges
                    Einwohnermeldeamt, Bankverbindung, Angaben zur Sozialversicherung (private oder
                    gesetzliche Krankenversicherung, Krankenkasse, RV-Option), Behindertenstatus,
                    Angaben zu früheren Arbeitsverhältnissen (Schulabschluss, Berufs- und
                    Ausbildungsdaten, Arbeitgeber:in, Beschäftigungszeit).<br/>
                    <br/>
                    Die Rechtsgrundlage für die Datenverarbeitung ist in dem Beschäftigungsverhältnis zu
                    sehen, Art. 6 Abs. 1 lit. b DS-GVO bzw. § 26 BDSG.<br/>
                    <br/>
                    Wir erheben und verarbeiten auch besondere Kategorien von personenbezogenen
                    Daten (Art. 9 Abs. 1 DS-GVO), soweit diese für eine Entscheidung über Ihre Bewerbung
                    erforderlich sind. Dazu können gehören: Angaben zu einem Behinderungsgrad sowie
                    vorhandene Merkzeichen sowie Angaben zu sonstigen gesundheitlichen
                    Einschränkungen.<br/>
                    <br/>
                    Die Rechtsgrundlage für die Datenverarbeitung findet sich insoweit in Art. 9 Abs. 2 lit. b
                    DS-GVO.<br/>
                    <br/>
                    Hinweise zur Löschung der Daten finden sich nachfolgend unter 2.4.<br/>
                    <br/>
                    Wird nach der Registrierung kein Anstellungsvertrag mit geschlossen, so werden die
                    Daten sobald ihre Kenntnis nicht mehr erforderlich sind, spätestens aber nach drei
                    Monaten nach Bekanntgabe der Absageentscheidung gelöscht, sofern einer Löschung
                    keine sonstigen berechtigten Interessen des für die Verarbeitung Verantwortlichen
                    entgegenstehen. Sonstiges berechtigtes Interesse in diesem Sinne ist beispielsweise eine
                    Beweispflicht in einem Verfahren nach dem Allgemeinen Gleichbehandlungsgesetz
                    (AGG).<br/>
                    <br/>
                    Deine Bewerbungsdaten werden nur denjenigen Personen und Stellen zugänglich
                    gemacht, die für die Personalgewinnung und Einstellungsentscheidung zuständig (z. B.
                    Personalabteilung, im Einzelfall relevante Entscheidungsträger) oder von
                    Gesetzeswegen an der Einstellung beteiligt (z. B. Betriebsrat) sind.<br/>
                    <br/>
                    Alle mit der Datenverarbeitung betrauten Mitarbeiter sind verpflichtet, die
                    Vertraulichkeit Ihrer Daten zu wahren. Die Verarbeitung der Daten findet ausschließlich
                    in Deutschland statt.<br/>
                    <br/>
                    Deine Daten werden an das Bewachungsregister weitergegeben. Dazu besteht eine
                    gesetzliche Pflicht.<br/>
                    <br/>
                    <b>2.4. Datenverarbeitung nach erfolgreicher Registrierung</b><br/>
                    Nachdem deine Registrierungsdaten auf Vollständigkeit geprüft wurden, wird dein
                    Account freigeschaltet. Du wirst nun ins Bewachungsregister eingetragen.<br/>
                    <br/>
                    Deine personenbezogenen Daten werden nun in einen Personalbogen aufgenommen,
                    der nicht elektronisch geführt wird.<br/>
                    <br/>
                    Aus der Datenbank unserer Webanwendung werden die von dir angegebenen Daten
                    gelöscht, mit Ausnahme deines Vor- und Nachnamens, deiner Wohnanschrift, deines
                    Geburtsdatum sowie deiner E-Mail-Adresse.<br/>
                    <br/>
                    Du kannst dich nun in deinem Account anmelden und deine Arbeitsbereitschaft für
                    bestimmte Veranstaltungen mitteilen. Zudem erhältst du E-Mail-Benachrichtigungen,
                    falls auf Neuigkeiten bezüglich des Accounts hinzuweisen ist.<br/>
                    Dies dient dem Zweck, das Beschäftigungsverhältnis übersichtlich und effizient zu
                    organisieren.<br/>
                    <br/>
                    Die Rechtsgrundlage für die Datenverarbeitung ist in dem Beschäftigungsverhältnis zu
                    sehen, Art. 6 Abs. 1 lit. b DS-GVO bzw. § 26 BDSG.<br/>
                    <br/>
                    Wird das Beschäftigungsverhältnis beendet, werden deine personenbezogenen Daten
                    gelöscht bzw. vernichtet. Jedoch können gesetzliche Aufbewahrungspflichten dazu
                    führen, dass personenbezogene Daten früherer Arbeitnehmer:innen für eine festgelegte
                    Dauer vorgehalten werden müssen. Diese Daten werden dann eingeschränkt, d.h. eine
                    andere Verarbeitung als zu dem gesetzlichen Aufbewahrungszweck ist nicht möglich.<br/>
                    <br/>
                    <b>2.5. Kontaktaufnahme</b><br/>
                    Bei einer Kontaktaufnahme mit uns per E-Mail werden die uns übermittelten
                    personenbezogenen Daten ( E-Mail-Adresse, ggfls. Name und Telefonnummer, ggfls.
                    Anliegen) für diese Kommunikation gespeichert.<br/>
                    Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung
                    nicht mehr erforderlich ist, oder die Verarbeitung wird eingeschränkt, falls gesetzliche
                    Aufbewahrungspflichten bestehen.<br/>
                    <br/>
                    <b>3. Löschung von personenbezogenen Daten</b><br/>
                    Neben den vorgenannten Löschfristen speichern wir gemäß den geltenden
                    Datenschutzbestimmungen deine personenbezogenen Daten nicht länger, als wir sie für
                    die Zwecke der jeweiligen Verarbeitung benötigen. Sofern die Daten nicht mehr zur
                    Erfüllung vertraglicher oder gesetzlicher Pflichten benötigt werden, werden sie von uns
                    regelmäßig gelöscht, es sei denn, ihre befristete Aufbewahrung ist weiterhin notwendig.<br/>
                    <br/>
                    Folgende Umstände können eine weitere befristete Aufbewahrung erfordern:<br/>
                    <br/>
                    Es müssen handels- oder steuerrechtliche Aufbewahrungspflichten eingehalten werden:<br/>
                    Die entsprechenden Aufbewahrungsfristen betragen in der Regel bis zu 10 Jahre (vgl.
                    insbesondere §§ 238 und 257 des Handelsgesetzbuchs sowie § 147 der
                    Abgabenordnung).<br/>
                    <br/>
                    Sind die Daten im Falle rechtlicher Streitigkeiten zum Erhalt von Beweismitteln
                    notwendig, kann ein berechtigtes Interesse an ihrer weiteren befristeten Aufbewahrung
                    im Rahmen der gesetzlichen Verjährungsvorschriften bestehen. (Die regelmäßige
                    Verjährung von Rechtsansprüchen tritt nach drei Jahren ein.)<br/>
                    <br/>
                    <b>4. Rechte der betroffenen Person</b><br/>
                    Wenn wir mit Euren personenbezogenen Daten umgehen, habt Ihr folgende Rechte:<br/>
                    • gemäß Art. 7 Abs. 3 DS-GVO Eure einmal erteilte Einwilligung jederzeit uns
                    gegenüber zu widerrufen. Dies hätte zur Folge, dass wir die Datenverarbeitung,
                    die auf dieser Einwilligung beruht, für die Zukunft nicht mehr fortführen dürften;
                    möchtet Ihr von Eurem Widerrufsrecht Gebrauch machen, genügt eine E-Mail an
                    mail@kuko-security.de<br/>
                    • gemäß Art. 15 DS-GVO Auskunft über Eure von uns verarbeiteten
                    personenbezogenen Daten zu verlangen. Insbesondere könnt Ihr Auskunft über
                    die Verarbeitungszwecke, die Kategorien der personenbezogenen Daten, die
                    Kategorien von Empfängern, gegenüber denen Eure Daten offengelegt wurden
                    oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf
                    Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das
                    Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht
                    bei uns erhoben wurden, sowie über das Bestehen einer automatisierten
                    Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen
                    Informationen zu deren Einzelheiten verlangen;<br/>
                    • gemäß Art. 16 DS-GVO unverzüglich die Berichtigung unrichtiger oder
                    Vervollständigung Eurer bei uns gespeicherten personenbezogenen Daten zu
                    verlangen;<br/>
                    • gemäß Art. 17 DS-GVO die Löschung Eurer bei uns gespeicherten
                    personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur
                    Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung
                    einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur
                    Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                    erforderlich ist;<br/>
                    • gemäß Art. 18 DS-GVO die Einschränkung der Verarbeitung Eurer
                    personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von
                    Euch bestritten wird, die Verarbeitung unrechtmäßig ist, Ihr aber deren
                    Löschung ablehnt und wir die Daten nicht mehr benötigen, Ihr jedoch diese zur
                    Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigt
                    oder Ihr gemäß Art. 21 DS-GVO Widerspruch gegen die Verarbeitung eingelegt
                    habt;<br/>
                    • gemäß Art. 20 DS-GVO Eure personenbezogenen Daten, die Ihr uns bereitgestellt
                    habt, in einem strukturierten, gängigen und maschinenlesebaren Format zu
                    erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen
                    und<br/>
                    • gemäß Art. 77 DS-GVO sich bei einer Aufsichtsbehörde zu beschweren. In der
                    Regel könnt Ihr Euch hierfür an die Aufsichtsbehörde Eures üblichen
                    Aufenthaltsortes oder Arbeitsplatzes oder unseres Vereinssitzes wenden.<br/>
                    <br/>
                    Ihr habt das Recht, aus Gründen, die sich aus Eurer besonderen Situation ergeben,
                    jederzeit gegen die Verarbeitung Euch betreffender personenbezogener Daten, die
                    aufgrund von Art. 6 Absatz 1 lit. e oder f DS-GVO erfolgt, Widerspruch einzulegen; dies
                    gilt auch für ein auf diese Bestimmungen gestütztes Profiling im Sinne von Art. 4 Nr. 4
                    DS-GVO. Der Kulturkosmos verarbeitet die personenbezogenen Daten dann nicht mehr,
                    es sei denn<br/>
                    • er kann zwingende, schutzwürdige Gründe für die Verarbeitung nachweisen,
                    welche die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen,
                    oder<br/>
                    • die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
                    Rechtsansprüchen.<br/>
                    <br/>
                    Möchtet Ihr von Eurem Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an mail@kuko-security.de.<br/>
                    <br/>
                    <b>5. Auftragsverarbeiter</b><br/>
                    Soweit wir uns externen Dienstleister (Auftragsverarbeiter) bedienen, ist mit dem
                    Dienstleister ein Auftragsverarbeitungsvertrag geschlossen, um den Schutz deiner
                    personenbezogenen Daten zu gewährleisten.
                </div>
            </div>
        </div>
    )
}

export default DataProtection;