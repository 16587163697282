import React from 'react';

function RadioGroup({name, label, value, onChange, helpText, options, inline}) {
  return (
    <div className="RadioGroup form-group">
      <label>{label}</label>
      <div className="mt-n1">
        {options && options.map((option, oI) => (
          <div className={`form-check${inline ? ' form-check-inline mr-3' : ''}`} key={oI}>
            <input id={`${name}-${oI}`} className="form-check-input" type="radio" value={option.value} name={name} checked={value === option.value} onChange={onChange ? () => onChange(option.value) : null}/>
            <label htmlFor={`${name}-${oI}`} className="form-check-label">{option.label}</label>
          </div>
        ))}
      </div>
      {helpText && (
        <span className="help-block small d-block">{helpText}</span>
      )}
    </div>
  )
}

export default RadioGroup;