import React, {Fragment, useState} from 'react';
import Button from "../../components/forms/Button";
import {Modal} from "react-bootstrap";
import moment from "moment";
import ProjectPersonApi from "../../api/ProjectPersonApi";
import {useDispatch} from "react-redux";
import {personLoadSuccess} from "../../store/person/personAction";
import DatePickerInput from "../../components/forms/DatePickerInput";
import TextArea from "../../components/forms/TextArea";
import SelectInput from "../../components/forms/SelectInput";

function ApplyForProject({project}) {

    const dispatch = useDispatch();

    const [plannedGettingThere, setPlannedGettingThere] = useState('');
    const [plannedDeparture, setPlannedDeparture] = useState('');
    const [plannedWorkingHours, setPlannedWorkingHours] = useState(24);
    const [comment, setComment] = useState('');
    const [errors, setErrors] = useState({});

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOpen = () => {
        setPlannedGettingThere('');
        setPlannedDeparture('');
        setPlannedWorkingHours(24);
        setComment('');
        setOpen(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        ProjectPersonApi.apply(project.id, plannedGettingThere, plannedDeparture, plannedWorkingHours, comment)
            .then(response => {
                setLoading(false);
                if(response.data.success) {
                    dispatch(personLoadSuccess(response.data.data));
                    setOpen(false);
                } else {
                    setErrors(response.data.errors);
                }
            })
    }

    return (
        <Fragment>
            <Button type="button" label={'Teilnahme anfragen'} onClick={handleOpen}/>
            <Modal show={open} onHide={() => setOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {project.displayName}
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                        Veranstaltungsdatum: {moment(project.startAt)?.format("DD.MM.")} - {moment(project.endAt)?.format("DD.MM.YYYY")}<br/>
                        Weitere Informationen:
                        <div style={{whiteSpace: 'pre-wrap'}}>{project.infoText}</div>
                        <br/><br/>
                        <div className="row">
                            <div className="col-6">
                                <DatePickerInput label="Mögliche Anreise"
                                                 dateFormat="DD.MM.YYYY"
                                                 timeFormat={false}
                                                 value={plannedGettingThere}
                                                 onChange={val => setPlannedGettingThere(val)}
                                                 initialViewDate={moment(project.startAt)}
                                                 error={errors?.plannedGettingThere}
                                                 closeOnSelect/>
                            </div>
                            <div className="col-6">
                                <DatePickerInput label="Mögliche Abreise"
                                                 dateFormat="DD.MM.YYYY"
                                                 timeFormat={false}
                                                 value={plannedDeparture}
                                                 onChange={val => setPlannedDeparture(val)}
                                                 initialViewDate={moment(project.startAt)}
                                                 error={errors?.plannedDeparture}
                                                 closeOnSelect/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <SelectInput name="plannedWorkingHours" value={plannedWorkingHours} onChange={val => setPlannedWorkingHours(val)}
                                             error={errors?.plannedWorkingHours} label="Gewünschte Arbeitszeit" options={[
                                    {
                                        value: 24,
                                        label: '24 Stunden'
                                    },
                                    {
                                        value: 30,
                                        label: '30 Stunden'
                                    },
                                    {
                                        value: 36,
                                        label: '36 Stunden'
                                    },
                                    {
                                        value: 42,
                                        label: '42 Stunden'
                                    },
                                    {
                                        value: 48,
                                        label: '48 Stunden'
                                    },
                                ]}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <TextArea name="comment" value={comment}
                                           onChange={val => setComment(val)}
                                           error={errors?.comment}
                                           label="Kommentar"/>
                            </div>
                        </div>

                        <br/><br/>
                        Sobald du deine Teilnahme anfragst, rechnen wir fest mit deiner Bereitschaft, uns zu unterstützen.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" onClick={() => setOpen(false)} color="secondary" label="Abbrechen"/>
                        <Button type="submit" color="primary" label="Teilnahme anfragen" loading={loading}/>
                    </Modal.Footer>
                </form>
            </Modal>
        </Fragment>
    )
}

export default ApplyForProject;