import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

function Button({type = 'submit', color = 'primary', label, loading = false, onClick, disabled = false}) {
    return (
        <button type={type} className={`btn btn-${color}`} onClick={onClick} disabled={disabled}>
            {loading && <FontAwesomeIcon icon={faSpinner} spin className="mr-2"/>}
            {label}
        </button>
    )
}

export default Button;