import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Button from "../forms/Button";
import {authorizationLogout} from "../../store/authorization/authorizationAction";
import NewsEntry from "./NewsEntry";
import moment from "moment";

function Sidebar() {

    const dispatch = useDispatch();
    let username = useSelector(state => state.authorization.username);
    let news = useSelector(state => state.person.news);

    return (
        <div className="Sidebar">
            <div className="card">
                <div className="card-body">
                    Eingeloggt als:<br/>
                    <b>{username}</b>
                    <div className="mt-3">
                        <Button type="button" label="Abmelden" onClick={() => dispatch(authorizationLogout())}/>
                    </div>
                </div>
            </div>
            <div className="card mt-3">
                <div className="card-body">
                    <b>Benötigst du Hilfe?<br/>
                        Hast du Fragen?</b><br/><br/>
                    Wende dich an<br/>
                    <a href="mailto:mail@kuko-security.de">
                        mail [at] kuko-security.de
                    </a>
                </div>
            </div>
            {news && news.length > 0 && (
                <div className="card mt-3">
                    <div className="card-header">
                        News
                    </div>
                    <div className="card-body">
                        {news.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))).map((ne, neI) => (
                            <NewsEntry newsEntry={ne} key={neI}/>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Sidebar;