import React, {useEffect, useState} from 'react';
import PersonApi from "../../api/PersonApi";

function SelectFileBox({value, onChange, label}) {

    const [uploading, setUploading] = useState(false);
    const [filename, setFilename] = useState('');

    useEffect(() => {
        if (value) {
            PersonApi.getFileInfo(value)
                .then(response => {
                    setFilename(response.data.data.originalFilename);
                })
        }
    }, [value])

    const uploadFile = (selectedFile) => {
        if (!selectedFile) return;
        setUploading(true);
        const data = new FormData();
        data.append('file', selectedFile);
        PersonApi.uploadFile(data)
            .then(response => {
                if (response.data.success) {
                    setUploading(false);
                    onChange(response.data.data.id);
                    setFilename(response.data.data.originalFilename);
                }
            })
    }

    return (
        <div className="form-group SelectFileBox">
            <label className="form-label">{label}</label>
            {value ? (
                <div><i>{filename}</i><button type="button" onClick={() => onChange(null)} className="btn btn-sm btn-warning ml-2">löschen</button></div>
            ) : (
                uploading ? (
                    <div className="progress">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                             aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{width: '100%'}}/>
                    </div>
                ) : (
                    <input className="form-control" type="file" id="formFile" accept="application/pdf,image/png,image/jpg,image/jpeg" onChange={(e) => uploadFile(e.target.files[0])}/>
                )
            )}
        </div>
    )
}

export default SelectFileBox;