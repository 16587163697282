import authorizationReducer from "./authorization/authorizationReducer";
import {combineReducers} from "redux";
import personReducer from "./person/personReducer";

const rootReducer = combineReducers({
    authorization: authorizationReducer,
    person: personReducer
});

const rootReducerWrapper = (state, action) => {
    return rootReducer(state, action);
}

export default rootReducerWrapper;