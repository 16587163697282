import axios from 'axios';
import {store} from '../store';
import {authorizationLogout} from "../store/authorization/authorizationAction";

class AxiosHelper {
    static init() {
        axios.interceptors.request.use((config) => {
            let token = store.getState().authorization.bearerToken;

            if (token) {
                config.headers.common['Authorization'] = `Bearer ${token}`
            }
            config.validateStatus = function(status) {
                return (status >= 200 && status < 300) || status === 404
            };
            return config;
        });

        axios.interceptors.response.use(null, function (error) {
            if (error.response && [401, 403].includes(error.response.status)) {
                store.dispatch(authorizationLogout());
            } else {
                alert('Please try again or contact administrators.');
            }

            return Promise.reject(error);
        });
    }
}

export default AxiosHelper;
