import React from 'react';

function SelectInput({name, label, value, onChange, helpText, options}) {
  return (
    <div className="SelectInput form-group">
      <label>{label}</label>
      <select name={name} className="form-control mt-n1" onChange={(e) => onChange ? onChange(e.target.value) : null}>
        {options && options.map((option, oI) => (
          <option key={oI} value={option.value} selected={option.value === value}>
            {option.label}
          </option>
        ))}
      </select>
      {helpText && (
        <span className="help-block small d-block">{helpText}</span>
      )}
    </div>
  )
}

export default SelectInput;