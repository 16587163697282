import axios from 'axios';

class ProjectPersonApi {
    static apply = (projectId, plannedGettingThere, plannedDeparture, plannedWorkingHours, comment) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/api/frontend/projectPerson/apply`, {
            project: projectId,
            plannedGettingThere,
            plannedDeparture,
            plannedWorkingHours,
            comment
        })
    };

    static update = (projectId, plannedGettingThere, plannedDeparture, plannedWorkingHours, comment) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/api/frontend/projectPerson/update`, {
            project: projectId,
            plannedGettingThere,
            plannedDeparture,
            plannedWorkingHours,
            comment
        })
    };
}

export default ProjectPersonApi;