import PersonApi from "../../api/PersonApi";
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {personLoadSuccess} from "./personAction";
import {PERSON_LOAD} from "../../config/actions";

const loadPersonFromApi = async () => {
  return PersonApi.load()
    .then(response => {
      if (response.data.success) return response.data.data
      else return null
    });
}


function* doLoadPerson() {
    const result = yield call(loadPersonFromApi);
    if (result) {
        yield put(personLoadSuccess(result))
    }
}

export function* loadPerson() {
    yield takeEvery(PERSON_LOAD, doLoadPerson);
}

export default function* rootSaga() {
    yield all([fork(loadPerson)]);
}