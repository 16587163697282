import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import Project from "./Project";
import {ROUTE_PROTECTED_PERSONAL_DATA} from "../../config/routes";
import {Link} from "react-router-dom";
import moment from "moment";
import DirectMessages from "./DirectMessages";

function Dashboard() {
    let availableProjects = useSelector(state => state.person.availableProjects);
    let personDataStatus = useSelector(state => state.person.person?.personDataStatus);

    return (
        <div className="Dashboard">
            {personDataStatus !== 'COMPLETE' && (
                <div className="alert alert-warning mb-3">
                    {personDataStatus === 'INCOMPLETE' && (
                        <div>
                            Um dich bei Veranstaltungen als Sicherheitskraft einzusetzen, benötigen wir einige persönliche Daten von dir.<br/>
                            <Link to={ROUTE_PROTECTED_PERSONAL_DATA}>
                                Daten jetzt eingeben
                            </Link>
                        </div>
                    )}
                    {personDataStatus === 'CREATED' && (
                        <div>
                            Du hast schon Daten eingegeben und gespeichert, allerdings noch nicht bei uns eingereicht.<br/>
                            Um dich beim Bundeszentralregister für Bewachung registrieren zu können benötigen wir zeitnah (!) deine Angaben.<br/>
                            Dann kannst du dich auch für die geplanten Veranstaltungen anmelden.<br/>
                            <Link to={ROUTE_PROTECTED_PERSONAL_DATA}>
                                Daten weiter bearbeiten
                            </Link>
                        </div>
                    )}
                    {personDataStatus === 'DECLINED' && (
                        <div>
                            Bei der Prüfung deiner Daten ist uns aufgefallen, dass diese noch nicht vollständig sind.<br/>
                            Um bei Veranstaltungen teilzunehmen, gib bitte noch die fehlenden Informationen ein.<br/>
                            <Link to={ROUTE_PROTECTED_PERSONAL_DATA}>
                                Daten jetzt vervollständigen
                            </Link>
                        </div>
                    )}
                    {personDataStatus === 'WAITING' && (
                        <div>
                            Vielen Dank, dass du all deine persönlichen Daten eingegeben hast.<br/>
                            Wir prüfen diese gerade und informieren dich schnellstmöglich.
                        </div>
                    )}
                </div>
            )}
            <div className="card">
                <div className="card-header">
                    <h5 className="mb-0">
                        Bevorstehende Veranstaltungen
                    </h5>
                </div>
                <div className="card-body">
                    {false && personDataStatus !== 'COMPLETE' ? (
                        <div>Sobald du deine persönlichen Daten angegeben und wir diese geprüft haben, kannst du die kommenden Veranstaltungen einsehen.</div>
                    ) : (
                        <Fragment>
                            {availableProjects?.filter(p => moment(p.endAt).isAfter(moment())).sort((a, b) => (a.startAt ? moment(a.startAt)?.valueOf() : 0) - (b.startAt ? moment(b.startAt)?.valueOf() : 0)).map((project, pI) => (
                                <Project key={pI} projectId={project.id}/>
                            ))}
                        </Fragment>
                    )}
                </div>
            </div>
            <DirectMessages/>
        </div>
    )
}

export default Dashboard;