import React from 'react';
import {useTranslation} from "react-i18next";
import InputError from "./InputError";

function TextArea({rows = 3, name, label, value, onChange, autoFocus, helpText, error, readonly = false}) {
    const {t} = useTranslation();
    return (
        <div className="TextInput form-group">
            <label>{label}</label>
            <textarea name={name} value={value} onChange={onChange ? (e) => onChange(e.target.value) : null}
                   className="form-control mt-n1" autoFocus={autoFocus} readOnly={readonly} rows={rows}/>
            {helpText && (
                <span className="help-block small d-block">{helpText}</span>
            )}
            <InputError error={error}/>
        </div>
    )
}

export default TextArea;