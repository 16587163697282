import React, {Fragment, useEffect} from "react";
import {Switch, Route} from "react-router-dom";
import {
    ROUTE_ACTIVATE, ROUTE_DATA_PROTECTION, ROUTE_FORGOT_PASSWORD, ROUTE_IMPRINT,
    ROUTE_LOGIN,
    ROUTE_PROTECTED_DASHBOARD,
    ROUTE_PROTECTED_PERSONAL_DATA,
    ROUTE_REGISTER_WITH_TOKEN, ROUTE_RESET_PASSWORD
} from "../config/routes";
import RegisterWithToken from "./RegisterWithToken";
import Activate from "./Activate";
import PersonalData from "./PersonalData";
import {useDispatch, useSelector} from "react-redux";
import Login from "./Login";
import Dashboard from "./Dashboard";
import Sidebar from "../components/Sidebar";
import AxiosHelper from "../config/axiosHelper";
import {personLoad} from "../store/person/personAction";
import TopBar from "../components/TopBar";
import Footer from "../components/Footer";
import Imprint from "./Imprint";
import DataProtection from "./DataProtection";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

function App() {

    let bearerToken = useSelector(state => state.authorization.bearerToken);
    const dispatch = useDispatch();

    useEffect(() => {
        AxiosHelper.init();
    }, []);

    useEffect(() => {
        if (bearerToken) dispatch(personLoad());
    }, [bearerToken, dispatch]);

    return (
        <div className="App">
            <TopBar centered={!bearerToken}/>
            <Switch>
                <Route exact path={ROUTE_IMPRINT} component={Imprint}/>
                <Route exact path={ROUTE_DATA_PROTECTION} component={DataProtection}/>
                <Route render={() => {
                    return (bearerToken ? (
                        <div className="container flex-fill">
                            <div className="row mt-5">
                                <div className="col-12 col-md-8">
                                    <Switch>
                                        <Route path={ROUTE_PROTECTED_PERSONAL_DATA} component={PersonalData}/>
                                        <Route path={ROUTE_PROTECTED_DASHBOARD} component={Dashboard}/>
                                    </Switch>
                                </div>
                                <div className="col-12 col-md-4">
                                    <Sidebar/>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Switch>
                            <Route path={ROUTE_FORGOT_PASSWORD} component={ForgotPassword}/>
                            <Route path={ROUTE_RESET_PASSWORD} component={ResetPassword}/>
                            <Route path={ROUTE_REGISTER_WITH_TOKEN} component={RegisterWithToken}/>
                            <Route path={ROUTE_ACTIVATE} component={Activate}/>
                            <Route path={ROUTE_LOGIN} component={Login}/>
                        </Switch>
                    ))
                }}
                />
            </Switch>
            <Footer/>
        </div>
    );
}

export default App;
