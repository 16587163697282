import React from 'react';

function Checkbox({name, label, value, onChange, helpText, readOnly, disabled, error}) {
    return (
        <div className="form-group Checkbox">
            <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id={name} checked={value}
                       onChange={onChange ? (e) => onChange(e.target.checked) : null} readOnly={readOnly}
                       disabled={disabled}/>
                <label className="custom-control-label" htmlFor={name} dangerouslySetInnerHTML={{__html: label}}/>
                {helpText && (
                    <span className="help-block small d-block">{helpText}</span>
                )}
                {error && (
                    <div className="help-block text-danger">
                        {error}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Checkbox;