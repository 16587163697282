import {
    AUTHORIZATION_LOGIN_SUCCESS,
    AUTHORIZATION_LOGOUT
} from "../../config/actions";

let defaultState = {
    bearerToken: null,
    username: null,
    roles: []
};

const authorizationReducer = (state = defaultState, action) => {
    switch (action.type) {
        case AUTHORIZATION_LOGIN_SUCCESS:
            return {
                ...state,
                bearerToken: action.bearerToken,
                username: action.username,
                roles: action.roles
            };
        case AUTHORIZATION_LOGOUT:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}

export default authorizationReducer;