import {AUTHORIZATION_LOGIN_SUCCESS, AUTHORIZATION_LOGOUT} from "../../config/actions";

export const authorizationLoginSuccess = (bearerToken, username, roles) => {
    return {
        type: AUTHORIZATION_LOGIN_SUCCESS,
        bearerToken,
        username,
        roles
    }
};

export const authorizationLogout = () => {
    return {
        type: AUTHORIZATION_LOGOUT
    }
};