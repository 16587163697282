import React from 'react';

function Imprint() {
    return (
        <div className="container mt-5 flex-fill">
            <div className="row">
                <div className="col-12">
                    <h2>Impressum</h2>
                    <p className="mt-5">
                        Angaben gemäß § 5 TMG:
                    </p>
                    <p>
                        Kuko Security GmbH<br/>
                        Am Flugplatz<br/>
                        17248 Lärz
                    </p>
                    <p>
                        Geschäftsführerin: C. Beck<br/>
                        Amtsgericht Neubrandenburg HRB 20983<br/>
                        Steuernummer: 075/112/03826 FA Waren
                    </p>
                    <p>
                        Telefon: 039833/27400<br/>
                        E-Mail: kontakt@kuko-security.de
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Imprint;