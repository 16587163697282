import React, {Fragment, useState} from "react";
import OutsideContainer from "../../components/layout/OutsideContainer";
import TextInput from "../../components/forms/TextInput";
import Button from "../../components/forms/Button";
import {useParams} from "react-router";
import UserApi from "../../api/UserApi";
import {useTranslation} from "react-i18next";
import Checkbox from "../../components/forms/Checkbox";

function RegisterWithToken() {
    const {t} = useTranslation();
    const [status, setStatus] = useState("showForm");
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [acceptDataProtection, setAcceptDataProtection] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    let token = useParams().token;

    let handleFormSubmit = (e) => {
        e.preventDefault();
        if (password !== password2) {
            setErrors({
                ...errors,
                password2: 'matchPassword'
            });
            return;
        }
        setLoading(true);
        UserApi.register(null, password, token, acceptDataProtection)
            .then(response => {
                setLoading(false);
                if (response.data.success) {
                    setStatus("success");
                } else {
                    setErrors(response.data.errors);
                }
            })
    };

    return (
        <OutsideContainer>
            <div className="RegisterWithToken">
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="mb-0">
                                    Account anlegen
                                </h5>
                            </div>
                            <div className="card-body">
                                {status === "showForm" && (
                                    <Fragment>
                                        <div>
                                            Du wurdest eingeladen, dir einen Account anzulegen.<br/>
                                            Bitte gib dein gewünschtes Passwort an.
                                        </div>
                                        {errors.token && (
                                            <div className="alert alert-warning mt-3">
                                                {t(`errors.token.${errors.token}`)}
                                            </div>
                                        )}
                                        <div className="mt-5">
                                            <form onSubmit={handleFormSubmit}>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <TextInput type="password" name="password" label="Passwort"
                                                                   autoFocus value={password} error={errors.password}
                                                                   onChange={val => setPassword(val)}/>
                                                    </div>
                                                    <div className="col-6">
                                                        <TextInput type="password" name="password2"
                                                                   error={errors.password2}
                                                                   label="Passwort wiederholen" value={password2}
                                                                   onChange={val => setPassword2(val)}/>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <Checkbox name="dataprotection" value={acceptDataProtection} onChange={val => setAcceptDataProtection(val)} error={errors.acceptDataProtection ? 'Bitte akzeptiere die Datenschutzerklärung' : null} label="Ich akzeptiere die <a href='/data-protection' target='_blank'>Datenschutzerklärung</a>."/>
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    <Button label="Konto anlegen" loading={loading}/>
                                                </div>
                                            </form>
                                        </div>
                                    </Fragment>
                                )}
                                {status === "success" && (
                                    <div>
                                        Vielen Dank, dass du deinen Account angelegt hast!<br/><br/>
                                        Du erhältst in Kürze eine E-Mail von uns, mit welcher du deinen Account
                                        aktivieren kannst.
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </OutsideContainer>
    );
}

export default RegisterWithToken;