import React, {useEffect, useState} from 'react';
import OutsideContainer from "../../components/layout/OutsideContainer";
import {Link} from "react-router-dom";
import {useParams} from "react-router";
import UserApi from "../../api/UserApi";
import {useTranslation} from "react-i18next";
import {ROUTE_LOGIN} from "../../config/routes";

function Activate() {
    const {t} = useTranslation();
    const [status, setStatus] = useState('loading');
    const [errors, setErrors] = useState({});
    let token = useParams().token;

    useEffect(() => {
        UserApi.activate(token)
            .then(response => {
                if (response.data.success) {
                    setStatus('success');
                } else {
                    setErrors(response.data.errors);
                    setStatus('error');
                }
            })
    }, [token])

    return (
        <OutsideContainer>
            <div className="Activate">
                <div className="row">
                    <div className="col-12 col-md-6 offset-md-3">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="mb-0">
                                    Account aktivieren
                                </h5>
                            </div>
                            <div className="card-body">
                                {status === 'loading' && (
                                    <div>Dein Account wird aktiviert...</div>
                                )}
                                {status === 'success' && (
                                    <div>
                                        Du hast deinen Account erfolgreich aktiviert.<br/><br/>
                                        Melde dich jetzt mit deinen Benutzerdaten an.<br/>
                                        <Link to={ROUTE_LOGIN}>
                                            Zum Login
                                        </Link>
                                    </div>
                                )}
                                {status === 'error' && (
                                    <div>
                                        Beim Aktivieren deines Accounts ist leider etwas schiefgelaufen.<br/>
                                        {t(`errors.token.${errors.token}`)}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </OutsideContainer>
    );
}

export default Activate;