import React, {useState} from 'react';
import OutsideContainer from "../../components/layout/OutsideContainer";
import TextInput from "../../components/forms/TextInput";
import Button from "../../components/forms/Button";
import {Link} from "react-router-dom";
import {ROUTE_LOGIN} from "../../config/routes";
import UserApi from "../../api/UserApi";

function ForgotPassword() {

    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        UserApi.forgotPassword(email)
            .then(response => {
                setLoading(false);
                if(response.data.success) {
                    setSuccess(true);
                } else {
                    setErrors(response.data.errors);
                }
            })
    }

    return (
        <OutsideContainer>
            <div className="ForgotPassword">
                <div className="row">
                    <div className="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="mb-0">
                                    Passwort vergessen?
                                </h5>
                            </div>
                            <div className="card-body">
                                {success ? (
                                    <div>Du solltest in Kürze eine E-Mail mit einem Link erhalten, mit welchem du dein Passwort neu setzen kannst.</div>
                                ) : (
                                    <form onSubmit={handleSubmit}>
                                        Du kannst einen Link anfordern, um ein neues Passwort zu vergeben.<br/><br/>
                                        <TextInput label="E-Mail-Adresse" value={email} onChange={val => setEmail(val)} error={errors.username}/>
                                        <Button type="submit" label="Absenden" loading={loading}/>
                                    </form>
                                )}
                                <div className="mt-3">
                                    <Link to={ROUTE_LOGIN}>
                                        Zurück zum Login
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </OutsideContainer>
    )
}

export default ForgotPassword;