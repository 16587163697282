import axios from 'axios';

class PersonApi {
    static load = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}/api/frontend/person`)
    }

    static getLastPersonalData = () => {
        return axios.get(`${process.env.REACT_APP_API_URL}/api/frontend/person/personalData`)
    }

    static savePersonalData = (payload) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/api/frontend/person/personalData`, payload)
    }

    static submitPersonalData = (payload) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/api/frontend/person/personalData/submit`, payload)
    }

    static uploadFile = (data) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/api/frontend/person/upload`, data)
    }

    static getFileInfo = (externalDocumentId) => {
        return axios.get(`${process.env.REACT_APP_API_URL}/api/frontend/person/file/${externalDocumentId}`)
    }
}

export default PersonApi;