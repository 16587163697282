export const ROUTE_LOGIN = '/';
export const ROUTE_REGISTER_WITH_TOKEN = '/register/:token';
export const ROUTE_ACTIVATE = '/activate/:token';

export const ROUTE_FORGOT_PASSWORD = '/forgotPassword';
export const ROUTE_RESET_PASSWORD = '/resetPassword/:token';

export const ROUTE_PROTECTED_DASHBOARD = '/';
export const ROUTE_PROTECTED_PERSONAL_DATA = '/personalData';

export const ROUTE_IMPRINT = '/imprint';
export const ROUTE_DATA_PROTECTION = '/data-protection';