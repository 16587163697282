import axios from 'axios';

class DirectMessageApi {
  static load = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/api/frontend/directMessage`)
  }

  static create = (message) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/api/frontend/directMessage`, {
      message
    })
  }
}

export default DirectMessageApi;