import React, {useEffect, useState} from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InputError from "../../../components/forms/InputError";
import DirectMessageApi from "../../../api/DirectMessageApi";
import {useSelector} from "react-redux";
import {css} from '@emotion/css';
import moment from "moment";

const DirectMessages = () => {

  let personState = useSelector(state => state.person);

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [errors, setErrors] = useState({});

  let loadMessagesInterval;

  useEffect(() => {
    if (!loadMessagesInterval && personState.person) {
      DirectMessageApi.load()
        .then(response => {
          setMessages(response.data.data);
        })
      loadMessagesInterval = setInterval(() => {
        DirectMessageApi.load()
          .then(response => {
            setMessages(response.data.data);
          })
      }, 5000);
    }

    return function cleanup() {
      if (loadMessagesInterval) clearInterval(loadMessagesInterval);
    }
  }, [personState.person])

  const handleCreateMessage = (e) => {
    e?.preventDefault();
    setIsCreating(true);
    setErrors(false);
    DirectMessageApi.create(newMessage)
      .then(response => {
        if (response.data?.success) {
          setMessages([...messages, response.data.data]);
          setNewMessage('');
          setIsCreating(false);
        } else {
          setErrors(response.data?.errors);
        }
      })
  }

  return (
    <div className="DirectMessages mt-5">
      <div className="card">
        <div className="card-header">
          <h5 className="mb-0">
            Nachrichten
          </h5>
        </div>
        <div className="card-body">
          <div className="messages-container bg-secondary rounded py-2 px-3">
            <ScrollToBottom className={css({height: 300})}>
              {messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).map((message, mI) => (
                <div className={`mt-2 pr-3 ${message.createdFromBackend ? '' : 'text-right'}`} key={mI}>
                  <div
                    className={`py-2 px-3 ${message.createdFromBackend ? 'bg-light' : 'bg-primary text-white'} text-left rounded d-inline-block`}
                    style={{maxWidth: '60%', minWidth: '40%', whiteSpace: 'pre-line'}}>
                    <div className="mb-n2">
                      <small>
                        <span className="text-muted float-right">
                            {moment(message.createdAt).format('DD.MM.YYYY, HH:mm')}
                        </span>
                        &nbsp;
                      </small>
                    </div>
                    {message.message}
                  </div>
                </div>
              ))}
            </ScrollToBottom>
          </div>
          <form onSubmit={handleCreateMessage}>
            <div className="row mt-4">
              <div className="col-9 pr-0">
              <textarea className="form-control" placeholder="Nachricht verfassen..." value={newMessage}
                        onChange={e => setNewMessage(e.target.value)} disabled={isCreating}/>
                <InputError error={errors.message}/>
              </div>
              <div className="col-3 align-self-end">
                <button type="submit" className="btn btn-primary btn-block" disabled={isCreating}>
                  {isCreating && <FontAwesomeIcon icon={faSpinner} spin className="mr-2"/>}
                  Absenden
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default DirectMessages;